import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
// core
import { ASUHeader } from "@asu-design-system/component-header/dist/asuHeader.es";

import desktopLogo from "../../../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../../../assets/img/arizona-state-university-logo.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';

import parseHtml from 'html-react-parser';
import { stripHtml } from "string-strip-html";
import { isInternalLink } from 'is-internal-link'

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    position: sticky;

    // this align the "Create" button
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

function Content(props) {
  const history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(Cookies.get('username'));
  const [targetNID, setTargetNID] = useState();
  const [menu, setMenu] = useState(props.data.menu);
  const [isCustom, setCustom] = useState(props.custom)


  const handleNavClick=(e)=> {
    e.preventDefault();
    const {result} = stripHtml(e.target.innerHTML);
    var nid = 0;
    // unity header component doesn't allow for adding attributes to links,
    // so we must find the nid by matching the title
    for (var i=0;i<menu.length;i++) {
      if ( result === menu[i].title ) {
        nid = menu[i].nid;
        setTargetNID(nid);
        if ( isCustom )
          history.push(menu[i].alias);
        else 
          history.push("/"+props.target+menu[i].alias);
      }
    }
  }

  // Builds navigation links and detects current page to load based on requested path.  Assumes the first link for the root page.
  const buildNav=()=> {
    var navTree = [];
    for ( var i=0;i<menu.length;i++) {
      let title  = menu[i].title;
      let nid = menu[i].nid;
      let alias = menu[i].alias;

      let path = decodeURIComponent(window.location.pathname);


      if ( isInternalLink(alias) ) {
        var isSelected = false;
        if ( 
          ( isCustom && path === alias || ( i === 0 && path === "/" ) ) 
          || 
          ( !isCustom && path === "/"+props.target+alias || ( i === 0 && path === "/"+props.target ) ) )
        {
          isSelected = true;
          if ( targetNID !== nid ) setTargetNID(nid);
        }
        navTree.push({
          onClick: (e) => handleNavClick(e),
          text: title,
          href: ( isCustom ? "/"+alias : "/"+props.target+alias ),
          type: "",
          selected: isSelected
        });
      } else {
        navTree.push({
          text: title,
          href: alias,
          class: "externalLink"
        });
      }
    }

    return navTree;
  }

  // unity header component doesn't allow for adding attributes to links so we have to
  // use the class "externalLink" to identify and modify external links to open in a new tab.
  const setExternalTargets = () =>
  {
    var links = Array.prototype.slice.apply(document.querySelectorAll("a.externalLink"));
    links.forEach(element => {
      element.setAttribute("target","_blank");
    });
  }

  let pageTitle = props.data.site_name;
  let nav = buildNav();

  // Assume page not found
  let content =
  <div className="container">
    <br />
    <div className='row alert alert-warning'>
      <div className='col-xs-4'>
        <FontAwesomeIcon icon='exclamation-triangle' size='2x' />
      </div>
      <div className='col-xs-8'>
        <b> Page Not Found. </b>
      </div>
    </div>
  </div>;

  props.data.content.forEach((i) => {
    if(targetNID === i.nid) {
      content = parseHtml(i.content);
    }
  });

  document.title = pageTitle;

  useEffect(() => {
    setExternalTargets();
  }, []);

  return (
    <div>
    <HeaderSection>
      <ASUHeader
        logo={{
          alt: "logo",
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        baseUrl={"/"+props.target+"/"}
        loggedIn={isAuthenticated}
        userName={user}
        onLoginClick={() => { window.open("https://sites.thecollege.asu.edu/cas"); }  }
        //onLogoutClick={() => { window.location.href = "https://thecollege.asu.edu/caslogout";setAuthenticated(false);}  }
        navTree={ nav }
        title={ pageTitle }
        buttons={[ ]}
      />
    </HeaderSection>

    { content }

    </div>
  );
}

export { Content };
