import React , { useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
// base components
import { ASUHeader } from "@asu-design-system/component-header/dist/asuHeader.es";
import { Footer } from "../core/components/Footer";
import desktopLogo from "../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../assets/img/arizona-state-university-logo.png";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie';

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    position: sticky;

    // this align the "Create" button
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

const messages = {
  DEFAULT: "Blank or Invalid faculty group specified.",
  NOTFOUND: "Page Not Found.",
  ERROR: "An Unknown error occured."
};

function DefaultRoute(props) {
  const history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(Cookies.get('username'));
  const stateRef = useRef();

  console.log(stateRef)

  var message = messages.DEFAULT;
  if ( window.location.pathname === "/404")
    message = messages.NOTFOUND;
  else if ( window.location.pathname === "/500")
    message = messages.ERROR;

  return (
    <div>
      <HeaderSection>
      <ASUHeader
        logo={{
          alt: "logo",
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={isAuthenticated}
        userName={user}
        onLoginClick={() => { window.open("https://sites.thecollege.asu.edu/cas"); }  }
        //onLogoutClick={() => { window.location.href = "https://thecollege.asu.edu/caslogout";setAuthenticated(false);}  }
        title="Faculty Sites"
      />
      </HeaderSection>
      <div className="main-container">
        <div className="container">
          <br />
          <div className="row alert alert-warning">
            <div className="col-xs-4">
              <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
            </div>
            <div className="col-xs-8">
              <b> { message } </b>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );

}

export default DefaultRoute;
