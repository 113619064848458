import './App.css';
import React from 'react'

import DefaultRoute from "./routes/DefaultRoute";
import TargetedRoute from "./routes/TargetedRoute";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect as redirect
} from 'react-router-dom';

library.add(faExclamationTriangle);

function App() {

  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = parts[0];

  // Remove trailing slash
  if (window.location.pathname.length > 1 && /\/$/.test(window.location.pathname)) {
    window.location.pathname = window.location.pathname.slice(0, -1);
  }

  if ( subdomain === "faculty" || subdomain === "lab" || subdomain.split(":")[0] === "localhost"  ) {
    console.log("Default site: "+subdomain);
    console.log("Path: "+window.location.pathname);

    return (
      <Router>
        <Switch>
          <Route exact path="/404" render={(props) => <DefaultRoute {...props} custom={false} />} />
          <Route exact path="/500" render={(props) => <DefaultRoute {...props} custom={false} />} />
          <Route exact path="/:target"  render={(props) => <TargetedRoute {...props} custom={false} />} />
          <Route exact path="/:target/:page" render={(props) => <TargetedRoute {...props} custom={false} />} />
          <Route exact path="/" render={(props) => <DefaultRoute {...props} custom={false} />} />
        </Switch>
      </Router>
    );
  }
  else {
    console.log("Custom site: "+subdomain);
    console.log("Path: "+window.location.pathname);
    return (
      <Router>
        <Switch>
          <Route exact path="/404" render={(props) => <DefaultRoute {...props} custom={true} />} />
          <Route exact path="/500" render={(props) => <DefaultRoute {...props} custom={true} />} />
          <Route exact path="/:page" render={(props) => <TargetedRoute {...props} page={window.location.pathname} target={subdomain} custom={true} />} />
          <Route exact path="/" render={(props) => <TargetedRoute {...props} page={window.location.pathname} target={subdomain} custom={true} />} />
        </Switch>
      </Router>
    );
  }

}

export default App;
