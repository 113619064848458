import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
// base components
import { Content, Footer } from "../core/components";
import LoadingOverlay from 'react-loading-overlay'
import SyncLoader from 'react-spinners/SyncLoader'
import styled from 'styled-components'
import axios from "axios";

const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
  }
  &._loading_overlay_spinner {
    top: 50%;
    left: 50%;
  }
`

function TargetedRoute(props) {
  console.log(props);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data,setData] = useState();
  const [isCustom, setCustom] = useState( props.custom );
  const [targetPage, setPage] = useState( ( isCustom ? props.page : props.match.params.page ) );
  const [targetGroup, setGroup] = useState( ( isCustom ? props.target : props.match.params.target ) );

  const getData=()=>{
    setIsLoading(true);
    axios({
      method: 'get',
      url: 'https://sites.thecollege.asu.edu/clas_group_api/'+targetGroup,
      withCredentials: false
      })
      .then((response) => {
        // Empty responses from the server are [] instead of {}.
        if ( !Array.isArray(response.data) ) {

          setData(response.data);

          setIsLoading(false);
        }
        //else {
        //  window.location.href = "/";
        //}
      })
      .catch(function (error) {
        console.log("Error caught!");
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);

        window.location.href = "/500";

        setIsLoading(false);
      });
  }

  useEffect( ()=>getData(),[] );

  return (
    <div>
    { isLoading === true ?
        <StyledLoader
          active={isLoading}
          spinner={<SyncLoader size="30px" color="#8C1D40" /> }
        >

        </StyledLoader>
      :
      <div className="main-container">
        <Content data={data} target={targetGroup} page={targetPage} custom={isCustom} ></Content>
        <Footer></Footer>
      </div>
    }
    </div>
  );

}


export default TargetedRoute;
